import { useCurrentUser } from '@grepp/main-common/src/services/exportables/use-current-user';
import { URLS } from '@grepp/main-common/src/services/urls.services';
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  NavigationGuard,
} from 'vue-router';

const Home = () => import('../components/home/Index.vue');
const SkillCheck = () => import('../components/skill-check/Index.vue');

const loginNavigationGuardBeforeEnter: NavigationGuard = async (
  to,
  from,
  next
) => {
  const currentUser = await useCurrentUser();

  if (currentUser.isLoggedIn) {
    if (
      to.name === 'MyAuthenticationManagement' &&
      (!currentUser.userInfo?.isCustomer || !currentUser.userInfo?.mfaEnabled)
    ) {
      next(from.fullPath);
    } else {
      next();
    }
  } else {
    next(`/account/sign_in?referer=${location.href}`);
  }
};

const businessNavigationGuardBeforeEnter: NavigationGuard = async (
  to,
  from,
  next
) => {
  const currentUser = await useCurrentUser();

  if (currentUser.isLoggedIn) {
    if (currentUser.userInfo?.isCustomer) {
      if (to.name === 'BusinessSecurity') {
        if (
          !currentUser.userInfo?.isTestManager ||
          (!currentUser.userInfo?.mfaEnabled &&
            !currentUser.userInfo?.networkRestrictionEnabled)
        )
          next(from.fullPath);
      }
      next();
    } else {
      next(from.fullPath);
    }
  } else {
    location.href = `${URLS.SIGN_IN}?referer=${location.href}`;
  }
};

const routes = [
  { name: 'home', path: '/', component: Home },
  { name: 'skill-check', path: '/skill_checks', component: SkillCheck },
  {
    name: 'users',
    path: '/users',
    meta: { hideGnbOnWebview: true },
    component: () => import('../components/my/Index.vue'),
    beforeEnter: loginNavigationGuardBeforeEnter,
    children: [
      {
        path: '',
        redirect: '/users/profile',
      },
      {
        path: 'profile', // 기본 정보
        name: 'MyProfile',
        component: () => import('../components/my/profile/Index.vue'),
      },
      {
        path: 'my_activity', // 나의 활동 내역
        name: 'MyActivity',
        component: () => import('../components/my/activity/Index.vue'),
      },
      {
        path: 'authentication_management', // 다중 인증 관리
        name: 'MyAuthenticationManagement',
        component: () =>
          import('../components/my/authentication-management/Index.vue'),
      },
      {
        path: 'orders', // 구매/신청 내역
        name: 'MyOrder',
        component: () => import('../components/my/orders/Index.vue'),
      },
    ],
  },
  {
    name: 'business',
    path: '/business',
    meta: { hideGnbOnWebview: false },
    component: () => import('../components/my/Index.vue'),
    beforeEnter: businessNavigationGuardBeforeEnter,
    children: [
      {
        path: '',
        redirect: '/business/company',
      },
      {
        path: 'company', // 기업 계정
        name: 'BusinessCompany',
        component: () => import('../components/my/company/Index.vue'),
      },
      {
        path: 'ticket', // 초대권
        name: 'BusinessTickets',
        component: () => import('../components/my/tickets/Index.vue'),
      },
      {
        path: 'security', // 보안
        name: 'BusinessSecurity',
        component: () => import('../components/my/security/Index.vue'),
      },
    ],
  },
  { name: 'fallback', path: '/:fallback*', component: Home },
];

const history =
  'window' in globalThis ? createWebHistory() : createMemoryHistory();

export const router = createRouter({
  history,
  routes,
});
