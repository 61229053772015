const mountFooter = async (container: HTMLDivElement) => {
  const { mount } = await import('mainGnb/Footer');
  mount(container);
};

export const useFooter = () => {
  return {
    mountFooter,
  };
};
