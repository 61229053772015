import { Cache } from '../../utils/cache';
import { CurrentUserStatus, fetchCurrentUserStatus } from '../api/current-user';

let currentUserCache: Cache<CurrentUserStatus> | null = null;

const useCurrentUser = () => {
  if (!currentUserCache) {
    currentUserCache =  new Cache<CurrentUserStatus>(fetchCurrentUserStatus);
  }
  return currentUserCache.get();
};

const clearCurrentUser = () => {
  currentUserCache && currentUserCache.reset();
}

export {
  useCurrentUser,
  clearCurrentUser,
};
