import { __ENV__, HostEnv } from './env';

type Hosts = {
  PROGRAMMERS: string,
  PROGRAMMERS_WS: string,
  SCHOOL: string,
  CAMPUS: string,
  CAREER: string,
  COMMUNITY: string,
  QNA: string,
  CERTI: string,
  BUSINESS: string,
  MINERVA: string,
};

type HostEnvKey = keyof HostEnv;

class DomainConverter {
  constructor(
    private key: string,
    private hostEnvKey: HostEnvKey,
    private converter: (domain: string) => string,
  ) {}

  hasKey(hostEnv: HostEnv) {
    return !!hostEnv[this.hostEnvKey];
  }

  convertToRecord(hostEnv: HostEnv) {
    return hostEnv[this.hostEnvKey] ? { [this.key]: this.converter(hostEnv[this.hostEnvKey] as string) } : {};
  }
}

const httpsConverter = (key: string, hostEnvKey: HostEnvKey) => {
  return new DomainConverter(key, hostEnvKey, (domain: string) => `https://${domain}`);
};

const wssConverter = (key: string, hostEnvKey: HostEnvKey) => {
  return new DomainConverter(key, hostEnvKey, (domain: string) => `wss://${domain}`);
};

const HOST_CONVERT_LIST = [
  httpsConverter('PROGRAMMERS', 'PROGRAMMERS_HOST'),
  wssConverter('PROGRAMMERS_WS', 'PROGRAMMERS_WS_HOST'),
  httpsConverter('SCHOOL', 'SCHOOL_HOST'),
  httpsConverter('CAMPUS', 'CAMPUS_HOST'),
  httpsConverter('CAREER', 'CAREER_HOST'),
  httpsConverter('BUSINESS', 'BUSINESS_HOST'),
  httpsConverter('MINERVA', 'MINERVA_URL'),
  httpsConverter('COMMUNITY', 'COMMUNITY_HOST'),
  httpsConverter('QNA', 'QNA_HOST'),
];

const convertToHosts = (hostEnv: HostEnv | undefined): HostEnv | null => {
  if (!hostEnv) {
    return null;
  }

  return HOST_CONVERT_LIST.reduce((acc, converter) => {
    if (converter.hasKey(hostEnv)) {
      return {
        ...acc,
        ...converter.convertToRecord(hostEnv),
      };
    }

    return acc;
  }, {} as HostEnv);
};

const DEFAULT_HOSTS = Object.freeze({
  PROGRAMMERS: 'https://programmers.co.kr',
  PROGRAMMERS_WS: 'wss://ws.programmers.co.kr',
  SCHOOL: 'https://school.programmers.co.kr',
  CAMPUS: 'https://campus.programmers.co.kr',
  CAREER: 'https://career.programmers.co.kr',
  COMMUNITY: 'https://community.programmers.co.kr',
  QNA: 'https://qna.programmers.co.kr',
  CERTI: 'https://certi.programmers.co.kr',
  BUSINESS: 'https://business.programmers.co.kr',
  MINERVA: 'https://minerva.programmers.co.kr',
});

export const HOSTS: Hosts = ((webappEnv: HostEnv| undefined) => {
  const webappHosts = convertToHosts(webappEnv) || {};

  return {
    ...DEFAULT_HOSTS,
    ...webappHosts,
  };
})(__ENV__);
