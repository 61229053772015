import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';

export type Stage = 'alpha' | 'beta' | 'prod';

export const DSN_MAP: { [key in Stage]: string } = {
  alpha:
    'https://977fd29965aa4b12b2abbaef1e7fae7d@o1070092.ingest.sentry.io/6152153',
  beta: 'https://977fd29965aa4b12b2abbaef1e7fae7d@o1070092.ingest.sentry.io/6152153',
  prod: 'https://96f9fc1ff3f44acbb98a9c156338d14b@o1070092.ingest.sentry.io/6136918',
};

export const useSentry = Object.assign(
  (app: App, router: Router, stage: Stage) => {
    if (useSentry.initialized) return Sentry;

    Sentry.init({
      app,
      dsn: DSN_MAP[stage],
      environment: stage,
      integrations: [
        new Sentry.BrowserTracing({
          ...(router && {
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          tracePropagationTargets: [
            'localhost',
            '*.programmers.co.kr',
            '*.monito.io',
            '*.beyondcampus.us',
            /^\//,
          ],
        }),
      ],
      tracesSampleRate: 0.01,
      beforeSend(event) {
        const [firstExeption] = event.exception?.values || [];
        if (firstExeption) {
          const { mechanism, stacktrace } = firstExeption;

          const isOnError = () => mechanism?.type === 'onerror';
          const hasAnonymousFilename = () =>
            Boolean(
              stacktrace?.frames?.some(({ filename }) =>
                filename?.includes('anonymous')
              )
            );

          /* console의 예외는 sentry에 보내지 않는다. #4226 */
          if (isOnError() && hasAnonymousFilename()) return null;
        }

        return event;
      },
    });
    useSentry.initialized = true;
    return Sentry;
  },
  { initialized: false }
);

export const setupSentry = (app: App, router: Router, stage: Stage) => {
  if (app.config) {
    const sentry = useSentry(app, router, stage);
    // eslint-disable-next-line no-param-reassign
    app.config.errorHandler = (error) => {
      sentry.captureException(error);
    };
  }
};
