export class Cache<T> {
  private data: Promise<T> | null = null;

  constructor(private provider: () => Promise<T>) {}

  get(): Promise<T> {
    if (!this.data) {
      this.data = this.prepareData();
    }

    return this.data;
  }

  reset() {
    this.data = null;
  }

  private prepareData() {
    return this.provider();
  }
}
