import { mountAppRoot } from './src/mount';

const main = () => {
  mountAppRoot();

  import('mainGnb/StoreUtmValues').then(({ storeUtmValues }) => {
    if (storeUtmValues) {
      storeUtmValues();
    }
  });
};

export default main;

export const app = main();
