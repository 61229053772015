import { sessionHttpClient } from './api.service';

export type CurrentUserStatus =
  | { timeZone: string } & (
      | {
          isLoggedIn: true;
          userInfo: UserInfo;
        }
      | { isLoggedIn: false }
    );

export type UserInfo = {
  id: number;
  name: string;
  email: string;
  confirmed: boolean;
  profileImageUrl: string;
  isCustomer: boolean;
  isTestManager?: boolean;
  networkRestrictionEnabled: boolean;
  mfaEnabled: boolean;
};

export const fetchCurrentUserStatus = async () => {
  const { data } = await sessionHttpClient.get<CurrentUserStatus>(
    '/v1/users/current'
  );

  return data;
};
