<template>
  <div>
    <RouterView />
    <div ref="footerRef"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, useCssModule, onMounted, ref, unref } from 'vue';
import { useFooter } from './services/exportables/footer';

export default defineComponent({
  name: 'AppRoot',
  props: {
    isWebapp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const style = useCssModule();

    const header = ref<HTMLDivElement | null>(null);
    const gnbRef = ref<HTMLDivElement | null>(null);
    const footerRef = ref<HTMLDivElement | null>(null);

    const renderGnb = async (gnbEl: HTMLElement) => {
      const { mount } = await import('mainHeader/mountHeader');
      mount(gnbEl);
    };

    const renderFooter = () => {
      const { mountFooter } = useFooter();
      const footer = unref(footerRef);
      footer && mountFooter(footer);
    };

    onMounted(() => {
      const gnbEl: HTMLElement | null =
        document.querySelector('#main-app-header');

      !props.isWebapp && gnbEl && renderGnb(gnbEl);
      renderFooter();
    });

    return {
      style,
      header,
      footerRef,
      gnbRef,
    };
  },
});
</script>
