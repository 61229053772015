declare global {
  interface Window {
    STAGE?: string;
    __env__?: WebappEnv;
  }
}

const MANDATORY_HOST_KEYS = [
  'PROGRAMMERS_HOST',
  'PROGRAMMERS_WS_HOST',
  'SCHOOL_HOST',
  'CAMPUS_HOST',
  'CAREER_HOST',
  'COMMUNITY_HOST',
  'QNA_HOST',
  'BUSINESS_HOST',
  'MINERVA_URL',
  'HERA_CLIENT_HOST',
] as const;

export type HostEnv = Partial<{
  [K in typeof MANDATORY_HOST_KEYS[number]]: string;
}>;

export type HackleInfo = {
  HACKLE_BROWSER_KEY: string;
  HACKLE_EVENT_SERVER_INIT: boolean;
};

type RuntimeEnv = {
  IS_PROGRAMMERS_APP_REQUEST: boolean;
};

type Site = {
  SITE: string;
};

type Stage = {
  STAGE: string;
};

type SentryDSN = {
  SENTRY_DSN: string;
};

export type WebappEnv = HostEnv &
  HackleInfo &
  RuntimeEnv &
  Site &
  Stage &
  SentryDSN;

export const __ENV__: WebappEnv = {
  // 개발환경 hackle browser key
  HACKLE_BROWSER_KEY: 'ye3WTBUUvZPicV5VEz5mKnyqa9qEhLtH',
  HACKLE_EVENT_SERVER_INIT: false,
  IS_PROGRAMMERS_APP_REQUEST: false,
  SITE: 'programmers',
  STAGE: 'development',
  SENTRY_DSN:
    'https://977fd29965aa4b12b2abbaef1e7fae7d@o1070092.ingest.sentry.io/6152153',
  ...(globalThis.window?.__env__ || {}),
};
