import { URLS } from '@grepp/main-common/src/services/urls.services';
import ActionCableVue from 'actioncable-vue';
import { createApp } from 'vue';
import { Stage, setupSentry } from '../../common/src/utils/sentry';
import App from './App.vue';
import { router } from './services/router.service';
import './assets/style/reset.scss';
import 'swiper/swiper.scss';

const actionCableVueOptions = {
  debug: process.env.NODE_ENV === 'development' ? true : false,
  debugLevel: 'all',
  connectionUrl: `${URLS.PROGRAMMERS_WS}/cable`,
};

export const mountAppRoot = async () => {
  if (process.env.NODE_ENV === 'development' && process.env.MSW) {
    const { worker } = await import('./mocks/browser');
    await worker.start({
      onUnhandledRequest: 'bypass',
    });
  }

  const id = 'main-app-root';
  const rootContainer =
    document.getElementById(id) ||
    document.body.appendChild(
      Object.assign(document.createElement('div'), { id })
    );

  const isWebapp = rootContainer.dataset.webapp === 'true';
  const app = createApp(App, { isWebapp });
  setupSentry(app, router, process.env.NODE_ENV as Stage);
  app.use(router);
  app.use(ActionCableVue, actionCableVueOptions);
  app.mount(rootContainer);
};
